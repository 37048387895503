.form{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;


    @include desktop{
        margin-top: 117px;
    }

    .inputs-wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 70px;
        }
    }

    &-label{
        width: 100%;

        @include desktop{
            max-width: 440px;
        }

        &.email{
            max-width: 890px;
        }
    }

    &-input{
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 2px solid #D9D9D9;
        padding: 30px 0px;
        font-weight: 700;
        font-size: 24px;
        text-transform: capitalize;
        color: $white;
        &::placeholder{
            color: $white;
        }
    }

    .btn{
        min-width: 160px;
        color: $black;
        border:none;
        @include desktop{

        
        max-width: 440px;
        }
       
    }


}