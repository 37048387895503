.header{
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: #2B2B2B;
    color: $white;

    @include desktop{
        padding-top: 14px;
        padding-bottom:14px;
    }

    .navigation{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
    }
    &-nav{
        display: none;

        @include desktop{
            display: flex;
            gap: 50px;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .icon{
        display: none;
        @include desktop{
            display: block;
        }
    }

    .burger-btn{
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include desktop{
            display: none;
        }
    }
}


