.privacy{
    padding-top: 100px;
    padding-bottom: 100px;

    font-weight: 600;
    font-size: 18px;

    @include desktop{
        padding-top: 150px;
        padding-bottom: 150px;
    }

    h1{
        font-weight: 700;
        font-size: 56px;

    }
        .disc{
            list-style: disc;
            padding-left: 20px;
        }

}