.hero{
    color: $white;
    padding-top: 80px;

    @include desktop{
        padding-top: 200px;
    }

    &.bg{
        background-image: url('../img/1.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        min-height: calc(100vh - 60px);
    }

    .text-wrapper{
        max-width: 550px;
        margin-right: auto;
    }

    .title{
        font-weight: 800;
        font-size: 56px;
        margin-bottom: 16px;
    }
    .subtitle{
        font-weight: 600;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 100px;
        @include desktop{
        margin-bottom: 171px;
        }
    }
    .box{
        max-width: 620px;
        margin-left: auto;
        border: 2px solid #fff;
        padding: 34px;
        font-weight: 600;
        font-size: 18px;
        text-align: justify;
    }


}


.values{

    .title{
        font-weight: 600;
            font-size: 24px;
            text-align: center;
            @include desktop{
                text-align: left;
            }
    }

    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        
        @include tablet{
            flex-direction: row;
            align-items: stretch;
            flex-wrap: wrap;
        }

        &__item{
            min-height: 566px;
            max-width: 100%;
            background: #eee;
            padding: 20px;

            display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: space-between;

                font-weight: 400;
                font-size: 18px;
            text-align: justify;
            @include tablet{
                max-width: 48%;
            }
            @include desktop{
                max-width: 23%;
            }
                &-logo{
                    padding: 10px 30px;
                    border: 1px solid $black;
                    border-radius: 100px;
                }

                &-title{
                    margin-bottom: 20px;
                    font-weight: 600;
                    font-size: 30px;
                    text-align: justify;
                }
        }

        .upper{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }


}

.contacts{

    @include desktop{
        padding-top: 0;
        padding-bottom: 50px;
    }

        .content-wrapper {
            min-height: calc(100vh - 371px); //710px height = header + section padding + footer
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @include desktop{
                align-items: flex-start;
            }

        }

        .title{
            font-weight: 700;
            font-size: 48px;
            line-height: 124%;
            margin-bottom: 32px;
        }

        .image{
            width: 100%;
            margin-bottom: 100px;
        }


}

.consulting{

    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        @include desktop{
            flex-direction: row;
        }
    }
    .descr{
        font-weight: 600;
        font-size: 24px;
        text-align: justify;
    }
    .text-container{
        max-width: 760px;
    }
    .title{
        font-weight: 800;
        font-size: 32px;
        margin-bottom: 50px;
    }

    .subtitle{
        font-size: 24px;
        text-align: justify;
    }
}


.services{

    .image{
        width: 100%;
        margin-bottom: 50px;
    }
    .title{
        font-weight: 800;
            font-size: 48px;
            text-align: justify;
        margin-bottom: 50px;
        text-align: center;
    }

    .list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 100px;
        align-items: center;
        @include tablet{
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }

        &__item{
            max-width: 100%;
            font-weight: 400;
                font-size: 18px;
            @include tablet{
                max-width: 350px;
            }

            &-title {
                margin-bottom: 20px;
            }
        }


    }
}


.contacts{
    background-color: $black;
    padding-top: 50px;
    padding-bottom: 150px;

    @include desktop{
        padding-top: 70px;
        padding-bottom: 100px;
    }

    
    .running {
        display: flex;
        width: 100%;
        overflow: hidden;           /* Скрываем текст за границами */
        background-color: #f0f0f0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    /* Стили текста */
    .running p {
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;
        font-size: 1.5em;
        font-weight: bold;
        color: #333;
        padding-right: 10px; /* Расстояние между копиями текста */
        animation: run 10s linear infinite;
    }

    /* Анимация для текста */
    @keyframes run {
        from {
            transform: translateX(0);     /* Начальная позиция */
        }
        to {
            transform: translateX(-100%); /* Конечная позиция, уход за левую границу */
        }
    }
}

// =================== success page
.success{

    .content-wrapper {
        min-height: calc(100vh - 501px); //710px height = header + section padding + footer
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 72px;

            @include tablet{
                min-height: calc(100vh - 372px); // height = header + section padding + footer
            }

            @include desktop {
                min-height: calc(100vh - 458px); // height = header + section padding + footer
                
            }
        }

        .title{
font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    text-transform: capitalize;
    text-align: center;
    @include desktop{
        font-size: 60px;
    }
        }
        .subtitle{
            font-weight: 700;
                font-size: 20px;

        }
                .link{
                    text-decoration: underline;
                }
}