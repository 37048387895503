.footer{
    margin-top: 100px;
    padding-bottom: 70px;
    padding-top: 40px;
    border-top: 2px solid $black;

    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 50px;
        @include desktop{
            flex-direction: row;
        }
    }

    .text-wrapper{
        width: 100%;
        max-width: 554px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;

        .rights{
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            margin-bottom: 50px;
            @include desktop{
                margin-bottom: 20px;
            }
        }

        p{
            max-width: 400px;
        }


    }


    .list{
        display:  flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 14px;
        margin-top: 24px;
        margin-bottom: 30px;

        font-weight: 500;
        font-size: 14px;
        line-height: 140%;

        a{
            font-weight: 500;
        }

        & span{
            font-weight: 900;
        }
    }

}
    

