@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 50px;
    max-width: 1440px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  fill: currentColor;
}

html,
body {
  font-family: "Inter", sans-serif;
  background-color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

li:last-child {
  margin-bottom: 0 !important;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

section {
  padding-top: 75px;
  padding-bottom: 75px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.2;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  color: inherit;
}
.logo:hover, .logo:focus {
  color: #fd1313;
}
.logo:active {
  color: inherit;
}

.link {
  font-weight: 800;
  font-size: 18px;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.link:hover, .link:focus {
  color: #fd1313;
}
.link:active {
  color: inherit;
}

.btn {
  width: 100%;
  border-radius: 100px;
  padding: 33px;
  background-color: #FFFFFF;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.hfa:hover, .hfa:focus {
  background-color: #b10000;
}
.hfa:active {
  background-color: #fd1313;
}

.just-validate-error-field.form-input {
  border-bottom: 2px solid #f27508;
}

.just-validate-error-label {
  display: none;
}

.header {
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: #2B2B2B;
  color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .header {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.header .navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header-nav {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header-nav {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: flex-end;
  }
}
.header .icon {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header .icon {
    display: block;
  }
}
.header .burger-btn {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .header .burger-btn {
    display: none;
  }
}

.footer {
  margin-top: 100px;
  padding-bottom: 70px;
  padding-top: 40px;
  border-top: 2px solid #000;
}
.footer .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
@media screen and (min-width: 1200px) {
  .footer .content-wrapper {
    flex-direction: row;
  }
}
.footer .text-wrapper {
  width: 100%;
  max-width: 554px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}
.footer .text-wrapper .rights {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 50px;
}
@media screen and (min-width: 1200px) {
  .footer .text-wrapper .rights {
    margin-bottom: 20px;
  }
}
.footer .text-wrapper p {
  max-width: 400px;
}
.footer .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  margin-top: 24px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}
.footer .list a {
  font-weight: 500;
}
.footer .list span {
  font-weight: 900;
}

/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: red;
}
.hystmodal__close:focus {
  color: red;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
.hystmodal.hystmodal--cookie .hystmodal__wrap {
  justify-content: flex-end;
}
.hystmodal.hystmodal--cookie .hystmodal__window {
  background-color: transparent;
  width: 100%;
  margin-bottom: 0;
  color: #FFFFFF;
}
.hystmodal.hystmodal--cookie .hystmodal__window .hystmodal__close {
  display: none;
}
.hystmodal.hystmodal--cookie .hystmodal__window .modal-content {
  color: #000;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(96, 96, 96, 0.1), 0 12px 24px -6px rgba(96, 96, 96, 0.15);
  background: #fff;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
@media screen and (min-width: 1200px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .modal-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .text-wrapper {
  max-width: 966px;
}
.hystmodal.hystmodal--cookie .hystmodal__window .title {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 175%;
}
.hystmodal.hystmodal--cookie .hystmodal__window .subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
    flex-direction: column;
    margin-top: 0;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn-cookie {
  width: 100%;
  max-width: 100%;
  min-width: 186px;
  background: transparent;
  border-radius: 0;
  border: 2px solid #000;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 189%;
  text-align: center;
  color: #000;
}

/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: black;
  padding: 22px;
}
.hystmodal.hystmodal--burger .logo {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .list {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.hero {
  color: #FFFFFF;
  padding-top: 80px;
}
@media screen and (min-width: 1200px) {
  .hero {
    padding-top: 200px;
  }
}
.hero.bg {
  background-image: url("../img/1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 60px);
}
.hero .text-wrapper {
  max-width: 550px;
  margin-right: auto;
}
.hero .title {
  font-weight: 800;
  font-size: 56px;
  margin-bottom: 16px;
}
.hero .subtitle {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 100px;
}
@media screen and (min-width: 1200px) {
  .hero .subtitle {
    margin-bottom: 171px;
  }
}
.hero .box {
  max-width: 620px;
  margin-left: auto;
  border: 2px solid #fff;
  padding: 34px;
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
}

.values .title {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .values .title {
    text-align: left;
  }
}
.values .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .values .list {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
.values .list__item {
  min-height: 566px;
  max-width: 100%;
  background: #eee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-weight: 400;
  font-size: 18px;
  text-align: justify;
}
@media screen and (min-width: 768px) {
  .values .list__item {
    max-width: 48%;
  }
}
@media screen and (min-width: 1200px) {
  .values .list__item {
    max-width: 23%;
  }
}
.values .list__item-logo {
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 100px;
}
.values .list__item-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  text-align: justify;
}
.values .list .upper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {
  .contacts {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.contacts .content-wrapper {
  min-height: calc(100vh - 371px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .contacts .content-wrapper {
    align-items: flex-start;
  }
}
.contacts .title {
  font-weight: 700;
  font-size: 48px;
  line-height: 124%;
  margin-bottom: 32px;
}
.contacts .image {
  width: 100%;
  margin-bottom: 100px;
}

.consulting .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
@media screen and (min-width: 1200px) {
  .consulting .content-wrapper {
    flex-direction: row;
  }
}
.consulting .descr {
  font-weight: 600;
  font-size: 24px;
  text-align: justify;
}
.consulting .text-container {
  max-width: 760px;
}
.consulting .title {
  font-weight: 800;
  font-size: 32px;
  margin-bottom: 50px;
}
.consulting .subtitle {
  font-size: 24px;
  text-align: justify;
}

.services .image {
  width: 100%;
  margin-bottom: 50px;
}
.services .title {
  font-weight: 800;
  font-size: 48px;
  text-align: justify;
  margin-bottom: 50px;
  text-align: center;
}
.services .list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .services .list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
.services .list__item {
  max-width: 100%;
  font-weight: 400;
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .services .list__item {
    max-width: 350px;
  }
}
.services .list__item-title {
  margin-bottom: 20px;
}

.contacts {
  background-color: #000;
  padding-top: 50px;
  padding-bottom: 150px;
  /* Стили текста */
  /* Анимация для текста */
}
@media screen and (min-width: 1200px) {
  .contacts {
    padding-top: 70px;
    padding-bottom: 100px;
  }
}
.contacts .running {
  display: flex;
  width: 100%;
  overflow: hidden; /* Скрываем текст за границами */
  background-color: #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.contacts .running p {
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  padding-right: 10px; /* Расстояние между копиями текста */
  animation: run 10s linear infinite;
}
@keyframes run {
  from {
    transform: translateX(0); /* Начальная позиция */
  }
  to {
    transform: translateX(-100%); /* Конечная позиция, уход за левую границу */
  }
}

.success .content-wrapper {
  min-height: calc(100vh - 501px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 72px;
}
@media screen and (min-width: 768px) {
  .success .content-wrapper {
    min-height: calc(100vh - 372px);
  }
}
@media screen and (min-width: 1200px) {
  .success .content-wrapper {
    min-height: calc(100vh - 458px);
  }
}
.success .title {
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-transform: capitalize;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .success .title {
    font-size: 60px;
  }
}
.success .subtitle {
  font-weight: 700;
  font-size: 20px;
}
.success .link {
  text-decoration: underline;
}

.form {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .form {
    margin-top: 117px;
  }
}
.form .inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (min-width: 1200px) {
  .form .inputs-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
  }
}
.form-label {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .form-label {
    max-width: 440px;
  }
}
.form-label.email {
  max-width: 890px;
}
.form-input {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 2px solid #D9D9D9;
  padding: 30px 0px;
  font-weight: 700;
  font-size: 24px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.form-input::placeholder {
  color: #FFFFFF;
}
.form .btn {
  min-width: 160px;
  color: #000;
  border: none;
}
@media screen and (min-width: 1200px) {
  .form .btn {
    max-width: 440px;
  }
}

.privacy {
  padding-top: 100px;
  padding-bottom: 100px;
  font-weight: 600;
  font-size: 18px;
}
@media screen and (min-width: 1200px) {
  .privacy {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
.privacy h1 {
  font-weight: 700;
  font-size: 56px;
}
.privacy .disc {
  list-style: disc;
  padding-left: 20px;
}