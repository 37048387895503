html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}

li{
  &:last-child{
    margin-bottom: 0!important;
  }
}

address {
  font-style: normal;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

section{
  padding-top: 75px;
  padding-bottom: 75px;
}


// ------------------------------  logo 
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.2;
  transition: $transition;
  color: inherit;
  &:hover,
    &:focus {


          color: $accent;
    }
  
    &:active {
      color: inherit;
    }

}


// ------------------------------ links 

.link{

  font-weight: 800;
  font-size: 18px;
  color: inherit;

  transition: $transition;

  
  &:hover,&:focus{
    color: $accent;
  }
  &:active{
    color: inherit;
  }
}


//btn
.btn{
  width: 100%;
  border-radius: 100px;
  padding: 33px;
  background-color: $white;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  color: $white;
  cursor: pointer;
}

// ------------------------------ btn hover focus active 
.hfa{
  transition: $transition;

  &:hover,&:focus{
    background-color: $second;
  }
  &:active{
    background-color: $accent;
  }
}

// opacity



//margin


//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border-bottom: 2px solid $error
}

.just-validate-error-label{

  display: none;
}




