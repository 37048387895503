
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;

  @include mobile {
    max-width: 430px;
  }

  @include tablet {
    max-width: 768px;
  }

  @include desktop {
    padding: 0 50px;
    max-width: 1440px;
  }
}