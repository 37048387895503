$color_1: red;
$background-color_1: black;
$background-color_2: transparent;


/* fix html, body */
.hystmodal__opened {
	position: fixed;
	right: 0;
	left: 0;
	overflow: hidden;
}
/* modal backdrop */
.hystmodal__shadow {
	position: fixed;
	border: none;
	display: block;
	width: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 98;
	opacity: 0;
	transition: opacity 0.15s ease;
	background-color: $background-color_1;
}
/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
	pointer-events: auto;
	opacity: 0.6;
}
/*  backdrop що огортає враппер модалки */
.hystmodal {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 1;
	pointer-events: none;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	z-index: 99;
	visibility: hidden;
}
/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
	pointer-events: auto;
	opacity: 1;
	.hystmodal__window {
		opacity: 1;
	}
}

.hystmodal--moved {
	pointer-events: auto;
	opacity: 1;
}

.hystmodal__wrap {
	flex-shrink: 0;
	flex-grow: 0;
	width: 100%;
	min-height: 100%;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}
.hystmodal__window {
	position: relative;
	margin: 50px 0;
	box-sizing: border-box;
	flex-shrink: 0;
	flex-grow: 0;
	background: #fff;
	width: 600px;
	max-width: 100%;
	overflow: visible;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
}
.hystmodal__close {
	position: absolute;
	z-index: 10;
	top: 20px;
	right: 20px;
	display: block;
	width: 30px;
	height: 30px;
	background-color: $background-color_2;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
	background-size: 100% 100%;
	border: none;
	font-size: 0;
	cursor: pointer;
	outline: none;
	transition: color 300ms ease-in-out;
	svg {
		fill: currentColor;
	}
	&:hover {
		color: $color_1;
	}
	&:focus {
		color: $color_1;
		outline: 2px dotted #afb3b9;
		outline-offset: 2px;
	}
    &:active{
        color: inherit;
    }
}

/* ------------ pop-up */
.hystmodal.hystmodal--cookie {
	.hystmodal__wrap{
		justify-content: flex-end;
	}
    .hystmodal__window {
			background-color: transparent;
			width: 100%;
			margin-bottom: 0;
			color: $white;

			& .hystmodal__close{
				display: none;
			}


			.modal-content{
				color: $black;
				overflow: hidden;
					box-shadow: 0 0 1px 0 rgba(96, 96, 96, 0.1), 0 12px 24px -6px rgba(96, 96, 96, 0.15);
				background: #fff;
				padding: 16px;
					font-weight: 500;
					font-size: 14px;
					line-height: 150%;
				@include desktop{
					display: flex;
					align-items: center;
					justify-content: space-between;
					max-width: 1440px;
					margin: 0 auto	
				} ;

			}


			.text-wrapper{
				max-width: 966px;
			}
			.title{
				display: flex;
				gap: 10px;
				margin-bottom: 30px;
				font-weight: 600;
				font-size: 24px;
				line-height: 175%;
			}
			.subtitle{

					font-weight: 400;
					font-size: 16px;
					line-height: 162%;
			}
			.btn-wrapper{
				margin-top: 16px;
				display: flex;
				flex-direction: row;
				gap: 15px;
				align-items: center;
				@include desktop{
					flex-direction: column;
					margin-top: 0;
				}
			}

			.btn-cookie{
				width: 100%;
				max-width: 100%;
				min-width: 186px;
				background: transparent;
				border-radius: 0;
				border: 2px solid #000;
				padding: 6px 16px;
				font-weight: 600;
				font-size: 16px;
				line-height: 189%;
				text-align: center;
				color: $black;

			}
    }
}



/* ------------ burger */
.hystmodal.hystmodal--burger {
	.hystmodal__wrap {
		justify-content: flex-start;
	}
	.hystmodal__window {
		margin: 0;
		width: 100%;
		height: 100%;
        color: black;
        padding: 22px;
	}

	.logo{
		justify-content: flex-start;
	}

	.list{
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}